@media only screen and (max-width:1232px) {
  .mainWritten>p {
    font-size: 14px;
  }
}


@media only screen and (max-width:1177px) {
  .facultyHeading {
    font-size: 48px;
  }

  .facultyLayerContent {
    padding-right: 10px;
  }

  .facultyContent {
    padding: 0 10px;
  }

  .facultyLayerContent {
    justify-content: center;
    gap: 20px;
  }

  .facultyImgDiv {
    width: 200px;
    height: 450px;
  }

  .facultyImg {
    width: 100%;
    object-position: left;
  }

  .facultyHeading {
    font-size: 40px;
  }

  .facultyName {
    font-size: 32px;
  }

  .facultyText {
    font-size: 14px;
  }
}


@media only screen and (max-width:1024px) {
  .aboutCards {
    height: 270px;
    width: 270px;
  }

  .aboutCardsText {
    font-size: 14px;
  }

  .eventsHeading {
    font-size: 40px;
  }

  .eventsSubHeading {
    font-size: 24px;
  }

  .bentoGrid {
    grid-template-columns: repeat(3, 160px);
    grid-template-rows: repeat(3, 150px);
  }

  .bentoGrid.bento2 {
    grid-template-columns: repeat(2, 240px);
    grid-template-rows: repeat(2, 225px);
  }

  .button-arrow1 {
    left: 8%;
  }

  .button-arrow2 {
    left: 16%;
  }
}

@media only screen and (max-width:995px) {
  .Main {
    grid-template-columns: auto;
  }

  .mainImg {
    order: 1;
    justify-self: center;
    width: 60vw;
    width: 60dvw;
    width: 60svw;
  }

  .mainWritten {
    order: 2;
    padding-top: 0;
  }

  .aboutCards {
    height: 250px;
    width: 250px;
  }

  .aboutCardsText {
    font-size: 14px;
  }

  .facultyLayerContent {
    justify-content: center;
    gap: 20px;
  }

  .facultyImgDiv {
    width: 200px;
    height: 400px;
  }

  .facultyImg {
    width: 100%;
    object-position: left;
  }

  .facultyHeading {
    font-size: 36px;
  }

  .facultyName {
    font-size: 24px;
  }

  .facultyText {
    font-size: 12px;
  }

  .founderHeading {
    font-size: 52px;
  }

  /* .teamMember{height: 300px; width: 168px;} */
}




@media only screen and (max-width: 770px) {
  body {
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
  }

  .App {
    background-image: linear-gradient(#f1f1fc, #c9c9ff, #E1CAFD, #b7e0fa, #afadec);
    transition: background-image 1s;
  }

  .navItems>li>button {
    display: none;
  }

  .navItems {
    grid-template-columns: auto auto;
    justify-content: space-between;
  }

  .Welcome {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-image: none;
    background-color: transparent;
    box-shadow: rgba(171, 26, 255, 0) 0px 2px 10px;
  }

  .About {
    height: 100vh;
    height: 100dvh;
    height: 100lvh;
    margin-top: 0;
  }

  .about_child {
    position: relative;
    background-image: none;
    background-color: transparent;
  }

  .aboutHeading {
    animation: none !important;
  }

  .aboutBee {
    animation: none !important;
    opacity: 1;
  }

  .aboutCards {
    height: 200px;
    width: 200px;
    animation: none !important;
    opacity: 1;
    transform: scale(1);
  }

  .aboutCardsText {
    font-size: 11px;
  }

  .Events {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-image: none;
    background-color: transparent;
    box-shadow: rgba(171, 26, 255, 0) 0px 2px 10px;
  }

  .Events {
    padding: 0px 0;
    margin-right: 20px;
    margin-left: 20px;
    height: 100vh;
    height: 100dvh;
    height: 100lvh;
  }

  .eventsCarousel {
    grid-template-columns: auto;
  }

  .eventsHeading {
    font-size: 56px;
  }

  .eventsSubHeading {
    text-align: center;
    font-size: 40px;
  }

  .bentoGrid,
  .bentoGrid.bento2 {
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    max-width: 100%;
    /* max-width: 100%; */
    height: 70vh;
    height: 70dvh;
    height: 70lvh;
    justify-content: flex-start;
    padding-bottom: 10px;
    overflow-y: hidden;

  }

  .bentoGrid::-webkit-scrollbar {
    display: none;
  }

  .carousel {
    flex-direction: row;
    overflow: hidden;
  }

  .button-arrow1 {
    top: 10%;
  }

  .button-arrow2 {
    top: 10%;
  }

  .bentoGrid>div {
    flex-shrink: 0;
    height: 55vh;
    height: 55dvh;
    height: 55lvh;
    width: 300px;
    animation: none !important;
  }

  .bentoGrid>div>img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover
      /* max-width: fit-content; */
  }

  .FacultyContainer {
    min-height: 100vh;
    min-height: 100dvh;
    min-height: 100lvh;
    margin-top: 0;
  }

  .Faculty {
    position: relative;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-image: none;
    background-color: transparent;
    box-shadow: rgba(171, 26, 255, 0) 0px 2px 10px;
  }

  .FacultyLayer {
    display: none;
  }

  .FacultyMobile {
    display: block;
  }

  .facultyContent {
    gap: 20px;
  }

  .facultyContent {
    grid-template-columns: auto;
    height: unset;
  }

  .FacultyMobile {
    order: 1;
  }

  .facultyImgDiv {
    order: 2;
    width: 400px;
    animation: none !important;
  }

  .facultyLayerContent {
    align-items: center;
    height: max-content;
    animation: none !important;
  }

  .facultyImg {
    object-fit: cover;
    object-position: top;
  }

  .facultyHeading {
    text-align: center;
  }

  .foundersContent {
    display: none;
  }

  .foundersContentMobile {
    display: flex;
    padding: 64px 20px 0;
    flex-direction: column;
    gap: 24px;
  }

  .founderScrollMobile {
    max-height: 300px;
    display: flex;
    gap: 20px;
    overflow-x: scroll;
  }

  .founderScrollMobile::-webkit-scrollbar {
    display: none;
  }

  .founderImgDiv {
    height: 90%;
    min-width: 250px;
    align-self: start;
    transform: translateY(0%);
  }

  .founderImgDiv.shivam {
    transform: translateY(0%);
    align-self: end;
  }

  .founderImg {
    /* height: 15rem; */
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 25px;
  }

  .founderHeading {
    font-size: 48px;
    text-align: center;
  }

  .teamContainer {
    height: max-content;
    margin-top: 50px;
    margin-bottom: 100px;
    background-color: transparent;
  }

  .Team2 {
    position: relative;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-image: none;
    background-color: transparent;
  }

  .next,
  .back {
    bottom: -100px;
  }

  .center .slick-center {
    transform: scale(1, 1);
    z-index: 11;
    transition: all 400ms ease-in-out;
  }

  .slick-slider {
    height: 480px;
    margin-left: 50px;
    /*-500 pehle*/
  }

  .slick-list {
    height: 520px;
  }

  .teamMember {
    height: 447px;
    min-width: 250px;
  }


  .Wall {
    max-height: 70vh;
    max-height: 70dvh;
    max-height: 70lvh;
    height: 100%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-image: none;
    background-color: transparent;
    /* margin: 0px 20px; */
    padding: 50px 20px;
  }

  /* @keyframes scale-in-mobile{ 
    0%{
      opacity:0;
      transform: translateY(-220%) scale(0.8); 
    }
    11.5%{
      opacity: 0.3;
      transform: translateY(-100%) scale(0.9);
    }
    30%{
      opacity: 1;
    }
    100%{
      opacity:1;
      transform: scale(1); 
    }
  } */
  .wallTextDiv {
    display: none;
  }

  .wallTextDiv_mobile {
    /* max-height: 100vh;
    max-height: 100dvh;
    max-height: 100lvh;  */
    max-height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
  }

  .wallCardsDiv {
    display: flex;
    overflow-x: scroll;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    overflow-y: hidden;
  }

  .wallCardsDiv::-webkit-scrollbar {
    display: none;
  }

  .wallText {
    /* transform: translateY(-100%); */
    animation: none !important;
    opacity: 1;
    transform: translateY(0) scale(1);
    animation-timeline: view();
    animation-range-start: cover;
    animation-range-end: contain;
    position: static;
  }

  .wallText {
    font-size: 60px;
  }


  .wallCards {
    animation: none !important;
    flex-shrink: 0;
    position: relative;
  }

  .footerContainer {
    min-height: 100vh;
    margin-top: 0;
  }

  .Footer {
    position: relative;
    background-color: transparent;
  }

  .footerjoinUs {
    border-radius: 25px;
    margin: 20px 20px;
    padding: 20px 0;
  }

  .footerContent {
    display: none;
  }

  .footerContent_mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .footerBMS>img {
    width: 100%;
  }

  .footerBMS {
    margin-top: 14px;
  }

  .footerCopyright>div {
    margin-bottom: 4px;
    font-size: 14px;
  }

  /* .eventsCarousel{width: max-content;} */


}


@media (orientation:portrait) {

  .welcomeText {
    font-size: 72px;
    top: 30%;
  }

  .logoBgdiv {
    width: 100%;
    top: 20%;
  }

  .logoBgdiv>img {
    object-fit: cover;
    width: 100%;
  }

  .navItems {
    margin: 0 24px
  }

  .mainWritten {
    padding: 20px 20px;
  }

  .aboutHeading {
    padding: 0px 0px 0px;
  }

  .About {
    padding: 0px 0;
    margin: 0 20px;
  }

  .about_child {
    gap: 20px;
  }

  .Events {
    padding: 0px 0px 0px;
  }

  .footerjoinUs {
    padding: 0px 0px;
  }

  .aboutMain {
    /* flex-direction: column; */
    overflow-x: scroll;
    gap: 20px;
    /* padding-left: 20px; */
    justify-content: flex-start;
    /* min-width: 1000px; */
    overflow-y: hidden;

  }

  .aboutMain::-webkit-scrollbar {
    display: none;
  }

  .wallText {
    font-size: 80px;
  }

  .footerjoinUs {
    grid-template-columns: auto;
  }

  .joinUs {
    padding: 0 30px;
  }

  .bloomPoster {
    height: 40vh;
    width: 40vh;
  }

  .spotlightHead {
    font-size: 40px;
  }

  .aboutCards {
    height: 300px;
    width: 300px;
    flex-shrink: 0;
  }

  .EventDiv {
    max-width: 90%;
    /* max-height: 70%; */
  }

  .eventPosterFlex {
    overflow-x: scroll;
    justify-content: flex-start;
    width: 100%;
    overflow-y: hidden;
  }

  .eventPosterFlex::-webkit-scrollbar {
    display: none;
  }

  .eventPosterFlex>img {
    flex-shrink: 0;
  }

  .facultyLayerContent {
    margin: 20px;
    padding: 0;
  }

  .facultyContent {
    padding: 0;
  }

  .facultyImgDiv {
    width: 90%;
    /* margin: 0 20px; */
  }

  .facultyText {
    width: 100%;
  }

  .facultyImg {
    object-position: left;
  }

  .Team2 {
    justify-content: center;
  }

  .teamHeading {
    font-size: 48px;
  }

  .teamMember {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 10px;
  }

  .next,
  .back {
    display: none;
  }

  .Wall {
    padding: 0px 20px 50px;
  }

  .wallCards {
    width: 300px;
    height: 171px;
    max-height: 100%;
  }

  .wallCards>img {
    width: 100%;
    object-fit: cover;
  }

  .center .slick-center {
    transform: scale(1, 1);
    z-index: 11;
    transition: all 400ms ease-in-out;
  }

  .slick-slider {
    margin-left: 10px;
  }

  /* .slick-list{display: flex;} */
  .slick-slide.slick-cloned {
    display: none;
  }

  .footerbgdesk {
    display: none;
  }

  .footerbgmobile {
    display: block;
  }
}

@media only screen and (max-width:380px) {
  .wallText {
    font-size: 48px;
  }
}